import { Config } from "../../../environment";
import GetPropertySnapshotModel from "../../../models/api/properties/GetPropertySnapshotModel";
import GetPreValuationModel from "../../../models/api/valuations/GetPreValuationModel";
import GetValuationModel from "../../../models/api/valuations/GetValuationModel";
import SetValuationModel from "../../../models/api/valuations/SetValuationModel";
import IHttpClient from "../../httpclient/IHttpClient";
import APIBase from "../APIBase";

export default class ValuationFormAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient)
  }

  SubmitPropertyDetails (model: Record<string, unknown>): Promise<GetPropertySnapshotModel> {
    return this.POST({
      Path: '/api/valuation_form/property_details',
      Body: model,
    })
  }

  SavePropertyDetails (model: Record<string, unknown>, propertySnapShotId: string): Promise<GetPropertySnapshotModel> {
    return this.POST({
      Path: `/api/valuation_form/${propertySnapShotId}/save_property_details`,
      Body: model,
    })
  }

  PrepaymentProcessSubmission (model: any): Promise<GetPreValuationModel> {
    return this.POST({
      Path: '/api/valuation_form/prepayment_process_submission',
      Body: {
        Address: model.AddressDetails.Address,
        CreateDate: model.AssessmentDetails.CreateDate,
        Purpose: model.AssessmentDetails.Purpose,
        RetrospectiveDate: model.AssessmentDetails.RetrospectiveDate,
        BusinessName: model.Contact.BusinessName,
        Email: model.Contact.Email,
        FirstName: model.Contact.FirstName,
        LastName: model.Contact.LastName,
        Phone: model.Contact.Phone,
        PropertyClassification: model.PropertyTypeSelection.PropertyClassification,
        PropertyType: model.PropertyTypeSelection.PropertyType,
      }
    })
  }

  CreatePreValuationForm (model: any): Promise<GetPreValuationModel> {
    return this.POST({
      Path: '/api/valuation_form/create_prevaluation_form',
      Body: {
        Address: model.AddressDetails.Address,
        CreateDate: model.AssessmentDetails.CreateDate,
        Purpose: model.AssessmentDetails.Purpose,
        RetrospectiveDate: model.AssessmentDetails.RetrospectiveDate,
        BusinessName: model.Contact.BusinessName,
        Email: model.Contact.Email,
        FirstName: model.Contact.FirstName,
        LastName: model.Contact.LastName,
        Phone: model.Contact.Phone,
        PropertyClassification: model.PropertyTypeSelection.PropertyClassification,
        PropertyType: model.PropertyTypeSelection.PropertyType,
      }
    })
  }

  CreateValuation (snapshotId: string, purpose: string, invoiceId: string, retrospectiveDate: string): Promise<GetValuationModel> {
    return this.POST({
      Path: `/api/valuation_form/valuations`,
      Body: {
        SnapshotId: snapshotId,
        Purpose: purpose,
        InvoiceId: invoiceId,
        PracticeId: Config.PracticeId,
        RetrospectiveDate: retrospectiveDate
      }
    })
  }
}
