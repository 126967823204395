import API from "./api/API";
import CredentialManager from "./credentialmanager/CredentialManager";
import EventBus from "./eventbus/EventBus";
import ServiceManager from "./ServiceManager";
import ServiceType from "./ServiceTypes";

class Services {
  private static _api: API | null = null;

  public static get API(): API {
    if (this._api === null) this._api = ServiceManager.Require(ServiceType.API) as API;

    return this._api;
  }

  private static _credentialManager: CredentialManager | null = null;

  public static get CredentialManager(): CredentialManager {
    if (this._credentialManager === null) this._credentialManager = ServiceManager.Require(ServiceType.CredentialManager) as CredentialManager;

    return this._credentialManager;
  }

  private static _eventBus: EventBus | null = null;

  public static get EventBus(): EventBus {
    if (this._eventBus === null) this._eventBus = ServiceManager.Require(ServiceType.EventBus) as EventBus;

    return this._eventBus;
  }
}

export default Services;