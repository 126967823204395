






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Page extends Vue {
  @Prop({ default: 'transparent' })
  private readonly background!: string;
}
