import { IHttpClient } from "../../..";
import { CountryModel, CreateUpdateCountryModel, CreateZoneModel, ZoneModel } from "../../../../models/api/admin";
import APIBase from "../../APIBase";

class AdminCountriesAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  GetCountries(): Promise<CountryModel[]> {
    return this.GET({ Path: '/api/admin/countries' })
  }

  GetCountry(id: string): Promise<CountryModel> {
    return this.GET({ Path: `/api/admin/countries/${id}` })
  }

  CreateCountry(model: CreateUpdateCountryModel): Promise<CountryModel> {
    return this.POST({ Path: '/api/admin/countries', Body: model })
  }

  UpdateCountry(id: string, model: CreateUpdateCountryModel): Promise<CountryModel> {
    return this.PATCH({ Path: `/api/admin/countries/${id}`, Body: model })
  }

  GetZones(countryId: string): Promise<ZoneModel[]> {
    return this.GET({ Path: `/api/admin/countries/${countryId}/zones` })
  }

  CreateZone(countryId: string, model: CreateZoneModel): Promise<ZoneModel> {
    return this.POST({ Path: `/api/admin/countries/${countryId}/zones`, Body: model })
  }
}

export default AdminCountriesAPI