import { IHttpClient } from "../..";
import BlogFilter from "../../../filters/BlogFilter";
import { BlogModel, CreateBlogModel, UpdateBlogModel } from "../../../models/api/blogs";
import { PagedResultSet } from "../../../models/api/common";
import APIBase from "../APIBase";

class BlogAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  GetBlog(blogId: string): Promise<BlogModel> {
    return this.GET({
      Path: `/api/blogs/${blogId}`
    })
  }

  CreateBlog(model: CreateBlogModel): Promise<BlogModel> {
    return this.POST({
      Path: '/api/blogs',
      Body: model
    })
  }

  UpdateBlog(blogId: string, model: UpdateBlogModel): Promise<BlogModel> {
    return this.PATCH({
      Path: `/api/blogs/${blogId}`,
      Body: model
    })
  }

  GetBlogs(filter: BlogFilter): Promise<PagedResultSet<BlogModel>> {
    return this.GET({
      Path: '/api/blogs',
      QueryParams: filter.ToQueryParams()
    })
  }
}

export default BlogAPI