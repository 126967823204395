import { IHttpClient } from "../..";
import LoginRequestModel from "../../../models/api/auth/LoginRequestModel";
import LoginResponseModel from "../../../models/api/auth/LoginResponseModel";
import PasswordResetRequestModel from "../../../models/api/auth/PasswordResetRequestModel";
import PasswordResetResponseModel from "../../../models/api/auth/PasswordResetResponseModel";
import SubmitPasswordResetRequestModel from "../../../models/api/auth/SubmitPasswordResetRequestModel";
import SubmitPasswordResetResponseModel from "../../../models/api/auth/SubmitPasswordResetResponseModel";
import APIBase from "../APIBase"

export default class AuthAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }
  
  public Login (model: LoginRequestModel): Promise<LoginResponseModel> {
    return this.POST({
      Path: '/api/auth/login',
      Body: model,
      Anon: true
    })
  }

  public RequestPasswordReset (model: PasswordResetRequestModel): Promise<PasswordResetResponseModel> {
    return this.POST({
      Path: '/api/auth/reset_password/request',
      Body: model,
      Anon: true
    })
  }

  public ResetPasswordSubmit (model: SubmitPasswordResetRequestModel): Promise<SubmitPasswordResetResponseModel> {
    return this.POST({
      Path: '/api/auth/reset_password/submit',
      Body:  model,
      Anon: true
    })
  }
}