import { IHttpClient } from "../..";
import GetPropertySnapshotModel from "../../../models/api/properties/GetPropertySnapshotModel";
import SetPropertySnapshotModel from "../../../models/api/properties/SetPropertySnapshotModel";
import GetCommercialDetailsModel from "../../../models/api/properties/Types/GetCommercialDetailsModel";
import GetIndustrialDetailsModel from "../../../models/api/properties/Types/GetIndustrialDetailsModel";
import GetResidentialDetailsModel from "../../../models/api/properties/Types/GetResidentialDetailsModel";
import GetRetailDetailsModel from "../../../models/api/properties/Types/GetRetailDetailsModel";
import GetRuralDetailsModel from "../../../models/api/properties/Types/GetRuralDetailsModel";
import APIBase from "../APIBase";

export default class PropertyAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  GetPropertySnapshot(propertySnapshotId: string): Promise<GetPropertySnapshotModel> {
    return this.GET({
      Path: `/api/properties/${propertySnapshotId}`
    })
  }

  async SavePropertySnapshot(model: SetPropertySnapshotModel): Promise<SetPropertySnapshotModel> {
    const result = await this.PATCH<SetPropertySnapshotModel>({
      Path: `/api/properties/${model.PropertySnapshotId}`,
      Body: model,
    })
    
    return model
  }

  GetCommercialDetails(propertySnapshotId: string): Promise<GetCommercialDetailsModel> {
    return this.GET({
      Path: `/api/properties/commercial/${propertySnapshotId}`
    })
  }

  async SaveCommercialDetails(model: GetCommercialDetailsModel): Promise<GetCommercialDetailsModel> {
    const result = await this.PATCH<GetCommercialDetailsModel>({
      Path: `/api/properties/commercial/${model.PropertySnapshotId}`,
      Body: model
    })
    
    return result
  }

  GetIndustrialDetails(propertySnapshotId: string): Promise<GetIndustrialDetailsModel> {
    return this.GET({
      Path: `/api/properties/industrial/${propertySnapshotId}`
    })
  }

  async SaveIndustrialDetails(model: GetIndustrialDetailsModel): Promise<GetIndustrialDetailsModel> {
    const result = await this.PATCH<GetIndustrialDetailsModel>({
      Path: `/api/properties/industrial/${model.PropertySnapshotId}`,
      Body: model
    })
    
    return result
  }

  GetResidentialDetails(propertySnapshotId: string): Promise<GetResidentialDetailsModel> {
    return this.GET({
      Path: `/api/properties/residential/${propertySnapshotId}`
    })
  }

  async SaveResidentialDetails(model: GetResidentialDetailsModel): Promise<GetResidentialDetailsModel> {
    const result = await this.PATCH<GetResidentialDetailsModel>({
      Path: `/api/properties/residential/${model.PropertySnapshotId}`,
      Body: model
    })
    
    return result
  }

  GetRetailDetails(propertySnapshotId: string): Promise<GetRetailDetailsModel> {
    return this.GET({
      Path: `/api/properties/retail/${propertySnapshotId}`
    })
  }

  async SaveRetailDetails(model: GetRetailDetailsModel): Promise<GetRetailDetailsModel> {
    const result = await this.PATCH<GetRetailDetailsModel>({
      Path: `/api/properties/retail/${model.PropertySnapshotId}`,
      Body: model
    })
    
    return result
  }

  GetRuralDetails(propertySnapshotId: string): Promise<GetRuralDetailsModel> {
    return this.GET({
      Path: `/api/properties/rural/${propertySnapshotId}`
    })
  }

  async SaveRuralDetails(model: GetRuralDetailsModel): Promise<GetRuralDetailsModel> {
    const result = await this.PATCH<GetRuralDetailsModel>({
      Path: `/api/properties/rural/${model.PropertySnapshotId}`,
      Body: model
    })
    
    return result
  }
}
