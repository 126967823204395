import Vue from 'vue'

import Page from './Page.vue'
import ContentRow from './ContentRow.vue'
import ContentContainer from './ContentContainer.vue'
import Masthead from './Masthead.vue'

import Row from './Row.vue'
import Column from './Column.vue'

import Cards from './Cards.vue'

Vue.component('Page', Page)
Vue.component('ContentRow', ContentRow)
Vue.component('ContentContainer', ContentContainer)
Vue.component('Masthead', Masthead)
Vue.component('Cards', Cards)

Vue.component('Row', Row)
Vue.component('Column', Column)
