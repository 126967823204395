class Utils {
  static GetGuid (): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8)

      return v.toString(16)
    })
  }

  static GetId (): string {
    return `_${Math.random().toString(36).substr(2, 9)}`
  }

  static Throttle (rate: number, callback: () => void): () => void {
    let lastTime = 0

    return () => {
      const now = new Date().getTime()

      if (now - lastTime >= rate) {
        callback()
        lastTime = now
      }
    }
  }
}

export default Utils