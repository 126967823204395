import { IHttpClient } from "../../..";
import { AddPostcodeInZoneModel, CreateZoneModel, PostcodeInZoneModel, UpdateZoneModel, ZoneModel } from "../../../../models/api/admin";
import APIBase from "../../APIBase";

class AdminZonesAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  GetZone(id: string): Promise<ZoneModel> {
    return this.GET({ Path: `/api/admin/zones/${id}` })
  }

  UpdateZone(id: string, model: UpdateZoneModel): Promise<ZoneModel> {
    return this.PATCH({ Path: `/api/admin/zones/${id}`, Body: model })
  }

  GetPostcodes(id: string): Promise<PostcodeInZoneModel[]> {
    return this.GET({ Path: `/api/admin/zones/${id}/postcodes` })
  }

  AddPostcode(id: string, postcode: string): Promise<PostcodeInZoneModel> {
    return this.POST({ Path: `/api/admin/zones/${id}/postcodes`, Body: { Postcode: postcode } as AddPostcodeInZoneModel })
  }

  DeletePostcode(id: string, postcode: string): Promise<boolean> {
    return this.DELETE({ Path: `/api/admin/zones/${id}/postcodes/${postcode}` })
  }
}

export default AdminZonesAPI