import { IHttpClient } from "../..";
import { GetFileModel } from "../../../models/api/files";
import APIBase from "../APIBase";

class FileAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  Upload(file: File): Promise<GetFileModel> {
    return this.POST({
      Path: '/api/files',
      Body: file
    })
  }

  GetFile (fileId: string): Promise<GetFileModel> {
    return this.GET({
      Path: `/api/files/${fileId}`
    })
  }

  async BulkGetFiles (fileIds: string[]): Promise<Record<string, GetFileModel>> {
    if (fileIds.length === 0) return {}

    const files = await this.GET<GetFileModel[]>({
      Path: `/api/files/bulk/${fileIds.join(',')}`
    })

    const map: Record<string, GetFileModel> = {}

    files.forEach((file) => {
      map[file.FileId] = file
    })

    return map
  }

  async Download (fileId: string, mode: 'newtab' | 'download' = 'download'): Promise<void> {
    const blob = await this.GETBlob({
      Path: `/api/files/${fileId}/download`
    })
    console.log(blob)

    const url = URL.createObjectURL(blob)

    console.log(url)

    if (mode === 'newtab') {
      window.open(url, '_blank')
    } else if (mode === 'download') {
      const file = await this.GetFile(fileId)

      const a = document.createElement('a')
      a.download = file.Filename
      a.href = url

      a.click()
    }

    setTimeout(() => {
      URL.revokeObjectURL(url)
    }, 5000)
  }
}

export default FileAPI