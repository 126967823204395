








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Btn extends Vue {
  @Prop()
  private readonly loading!: boolean;
}
