

























import { Component, Prop, Vue } from 'vue-property-decorator'
import NavigationLink from './NavigationLink.vue'

@Component({
  components: {
    NavigationLink
  }
})
export default class Navigation extends Vue {
  @Prop()
  private readonly mobileNavActive!: boolean

  goToHowItWorks (): void {
    const elem = document.querySelector('#how-it-works')

    if (elem === null) {
      this.$router.push({
        path: '/#how-it-works'
      })
    } else {
      elem.scrollIntoView({
        behavior: 'smooth'
      })
    }
    this.$emit('toggleMobileNavActive')
  }
}
