import { IHttpClient } from "../..";
import APIBase from "../APIBase";

class TelemetryAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  Ping(): Promise<boolean> {
    return new Promise((resolve) => {
      this.GET({
        Path: '/api/telemetry/ping',
        Anon: true
      }).then(() => {
        resolve(true)
      }).catch(() => {
        resolve(false)
      })
    })
  }
}

export default TelemetryAPI