














import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class GridNavItem extends Vue {
  @Prop({ required: true })
  private readonly to!: string;

  @Prop({ default: false })
  private readonly external!: boolean;

  @Prop({ default: '' })
  private readonly icon!: string;

  @Prop({ default: '' })
  private readonly title!: string;
}
