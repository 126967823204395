import IHttpClient from "../../../httpclient/IHttpClient";
import AdminCountriesAPI from "./AdminCountriesAPI";
import AdminCRMAPI from "./AdminCRMAPI";
import AdminZonesAPI from "./AdminZonesAPI";
import FranchiseAPI from "./FranchiseAPI";

class AdminAPI {
  Countries: AdminCountriesAPI;
  Zones: AdminZonesAPI;
  Franchises: FranchiseAPI;
  CRM: AdminCRMAPI;

  constructor(httpClient: IHttpClient) {
    this.Countries = new AdminCountriesAPI(httpClient);
    this.Zones = new AdminZonesAPI(httpClient);
    this.Franchises = new FranchiseAPI(httpClient);
    this.CRM = new AdminCRMAPI(httpClient);
  }
}

export default AdminAPI
