import { InvoiceModel } from "../../../models/api/invoices";
import { GetStripePaymentModel } from "../../../models/api/payment/stripe";
import CacheMode from "../../httpclient/CacheMode";
import IHttpClient from "../../httpclient/IHttpClient";
import APIBase from "../APIBase";

export default class PaymentAPI extends APIBase {
  constructor(client: IHttpClient) {
    super(client)
  }

  public GetInvoices(): Promise<InvoiceModel[]> {
    return this.GET({
      Path: '/api/invoices'
    })
  }

  public GetInvoice(invoiceId: string): Promise<InvoiceModel> {
    return this.GET({
      Path: `/api/invoices/${invoiceId}`
    })
  }

  public GetInvoiceHTML(invoiceId: string): Promise<Blob> {
    return this.GETBlob({
      Path: `/api/invoices/${invoiceId}/export/html`
    })
  }

  public GetInvoicePDF(invoiceId: string): Promise<Blob> {
    return this.GETBlob({
      Path: `/api/invoices/${invoiceId}/export/pdf`
    })
  }

  public CreateTestInvoice(): Promise<InvoiceModel> {
    return this.POST({
      Path: '/api/invoices/create_test_invoice'
    })
  }

  public SendEmailInvoice(invoiceId: string): Promise<Blob> {
    return this.GETBlob({
      Path: `/api/invoices/${invoiceId}/export/email`
    })
  }

  public CreateInvoicePaymentIntent (invoiceId: string): Promise<GetStripePaymentModel> {
    return this.POST({
      Path: `/api/payments/stripe/invoices/${invoiceId}/payment_intent`,
      CacheMode: CacheMode.NoCache
    })
  } 
}
