import { Logger, LogLevel } from "../debug/Logger";
import IConfig from "./IConfig";
import APIConfig from "./modules/APIConfig";
import GoogleConfig from "./modules/GoogleConfig";
import HttpConfig from "./modules/HttpConfig";
import LoggingConfig from "./modules/LoggingConfig";
import OAuthConfig from "./modules/OAuthConfig";

class Config {
  static API: APIConfig;
  static Http: HttpConfig;
  static Logging: LoggingConfig;
  static OAuth: OAuthConfig;
  static Google: GoogleConfig;
  static PracticeId?: string;

  public static Initialise(config: IConfig): void {
    this.API = config.API;
    this.Http = config.Http;
    this.Logging = config.Logging;
    this.OAuth = config.OAuth;
    this.Google = config.Google;
    this.PracticeId = config.PracticeId;

    Logger.Log(LogLevel.Debug, 'Environment', 'Initialised config');
  }
}

export default Config;