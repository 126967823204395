import { Services } from "..";
import { Config } from "../../environment";
import CredentialManager from "../credentialmanager/CredentialManager";
import HttpRequest from "../httpclient/HttpRequest";
import IHttpClient from "../httpclient/IHttpClient";
import APIRequestInit from "./APIRequestInit";

class APIBase {
  protected HttpClient: IHttpClient;
  protected CredentialManager: CredentialManager;

  constructor(httpClient: IHttpClient) {
    this.HttpClient = httpClient;
    this.CredentialManager = Services.CredentialManager;
  }

  private GetUrl(path: string): string {
    if (path.startsWith('http')) return path;

    return `${Config.API.Base}${path}`;
  }

  protected GET<TResponse>(init: APIRequestInit): Promise<TResponse> {
    const req = HttpRequest.GET(this.GetUrl(init.Path), init.QueryParams, init.CacheMode)

    if (!init.Anon) {
      const credential = this.CredentialManager.GetToken();

      if (credential !== null) {
        req.Headers['Authorization'] = `Bearer ${credential.Token}`;
      } else {
        console.error('No credentials available')
        // return Promise.reject('No credentials available')
      }
    }

    return this.HttpClient.Request<TResponse>(req) as Promise<TResponse>;
  }

  protected GETBlob(init: APIRequestInit): Promise<Blob> {
    const req = HttpRequest.GET(this.GetUrl(init.Path), init.QueryParams, init.CacheMode)

    if (!init.Anon) {
      const credential = this.CredentialManager.GetToken();

      if (credential !== null) {
        req.Headers['Authorization'] = `Bearer ${credential.Token}`;
      } else {
        console.error('No credentials available')
        // return Promise.reject('No credentials available')
      }
    }

    return this.HttpClient.RequestBlob(req) as Promise<Blob>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected POST<TResponse>(init: APIRequestInit): Promise<TResponse> {
    const req = HttpRequest.POST(this.GetUrl(init.Path), init.Body);

    if (!init.Anon) {
      const credential = this.CredentialManager.GetToken();

      if (credential === null) throw new Error('No credential available');

      req.Headers['Authorization'] = `Bearer ${credential.Token}`;
    }

    return this.HttpClient.Request<TResponse>(req) as Promise<TResponse>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected PATCH<TResponse>(init: APIRequestInit): Promise<TResponse> {
    const req = HttpRequest.PATCH(this.GetUrl(init.Path), init.Body);

    if (!init.Anon) {
      const credential = this.CredentialManager.GetToken();

      if (credential === null) throw new Error('No credential available');

      req.Headers['Authorization'] = `Bearer ${credential.Token}`;
    }

    return this.HttpClient.Request<TResponse>(req) as Promise<TResponse>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected PUT<TResponse>(init: APIRequestInit): Promise<TResponse> {
    const req = HttpRequest.PUT(this.GetUrl(init.Path), init.Body);

    if (!init.Anon) {
      const credential = this.CredentialManager.GetToken();

      if (credential === null) throw new Error('No credential available');

      req.Headers['Authorization'] = `Bearer ${credential.Token}`;
    }

    return this.HttpClient.Request<TResponse>(req) as Promise<TResponse>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected DELETE<TResponse>(init: APIRequestInit): Promise<TResponse> {
    const req = HttpRequest.DELETE(this.GetUrl(init.Path), init.Body);

    if (!init.Anon) {
      const credential = this.CredentialManager.GetToken();

      if (credential === null) throw new Error('No credential available');

      req.Headers['Authorization'] = `Bearer ${credential.Token}`;
    }

    return this.HttpClient.Request<TResponse>(req) as Promise<TResponse>;
  }
}

export default APIBase;
