













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Masthead extends Vue {
  private imageLoaded = false

  @Prop({ default: '' })
  private readonly image!: string;
}
