









import { Component, Vue } from 'vue-property-decorator'
import Header from './components/Layout/Header.vue'
import Footer from './components/Layout/Footer.vue'

import './assets/css/reset.css'
import './assets/css/base.css'
import './assets/css/forms.css'

@Component({
  components: {
    Header,
    Footer
  }
})
export default class App extends Vue {
  isContactUsShown = false

  handleSetContactUs (state: boolean): void {
    this.isContactUsShown = state
  }
}
