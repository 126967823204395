enum ServiceType {
  HttpClient = 'httpClient',
  API = 'api',
  BrowserStorage = 'browserStorage',
  Head = 'head',
  CredentialManager = 'credentialManager',
  EventBus = 'eventBus',
  Stripe = 'stripe'
}

export default ServiceType;