import IHttpClient from "../httpclient/IHttpClient";
import { Service } from "../IService";
import ServiceManager from "../ServiceManager";
import ServiceType from "../ServiceTypes";
import IAPI from "./IAPI";
import AdminAPI from "./types/admin/AdminAPI";
import PaymentAPI from "./types/PaymentAPI";
import BlogAPI from "./types/BlogAPI";
import BusinessAPI from "./types/BusinessAPI";
import CRMAPI from "./types/CRMAPI";
import PropertyAPI from "./types/PropertyAPI";
import ProductAPI from "./types/ProductAPI";
import EnumerationsAPI from "./types/EnumerationsAPI";
import FileAPI from "./types/FileAPI";
import GoogleAPI from "./types/GoogleAPI";
import TelemetryAPI from "./types/TelemetryAPI";
import UsersAPI from "./types/UsersAPI";
import ValuationFormAPI from "./types/ValuationFormAPI";
import ValuationAPI from "./types/ValuationAPI";
import ReportsAPI from "./types/ReportsAPI";
import AuthAPI from "./types/AuthAPI";
import PracticesAPI from "./types/PracticesAPI";
import DateAPI from "./types/DateAPI";

class API implements Service, IAPI {
  Type: ServiceType = ServiceType.API;
  Id = 'API';

  private HttpClient: IHttpClient = ServiceManager.Require(ServiceType.HttpClient) as IHttpClient;

  Auth: AuthAPI = new AuthAPI(this.HttpClient);
  Users: UsersAPI = new UsersAPI(this.HttpClient);
  Businesses: BusinessAPI = new BusinessAPI(this.HttpClient);
  Telemetry: TelemetryAPI = new TelemetryAPI(this.HttpClient);
  Files: FileAPI =  new FileAPI(this.HttpClient);
  Blogs: BlogAPI = new BlogAPI(this.HttpClient);
  Dates: DateAPI = new DateAPI(this.HttpClient);

  Admin: AdminAPI = new AdminAPI(this.HttpClient);

  Payment: PaymentAPI = new PaymentAPI(this.HttpClient);

  Google: GoogleAPI = new GoogleAPI(this.HttpClient);
  Enumerations: EnumerationsAPI = new EnumerationsAPI(this.HttpClient);
  Valuations: ValuationAPI = new ValuationAPI(this.HttpClient);

  CRM: CRMAPI = new CRMAPI(this.HttpClient);
  Property: PropertyAPI = new PropertyAPI(this.HttpClient);
  Product: ProductAPI = new ProductAPI(this.HttpClient);

  ValuationForm: ValuationFormAPI = new ValuationFormAPI(this.HttpClient);

  Reports: ReportsAPI = new ReportsAPI(this.HttpClient);

  Practices: PracticesAPI = new PracticesAPI(this.HttpClient);
}

export default API;
