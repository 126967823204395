import { IHttpClient } from "../..";
import { InvoiceModel } from "../../../models/api/invoices";
import { GetProductModel } from "../../../models/api/products";
import APIBase from "../APIBase";

export default class ProductAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  GetProduct (productId: string): Promise<GetProductModel> {
    return this.GET({
      Path: `/api/products/${productId}`
    })
  }

  CreateInvoice (productId: string): Promise<InvoiceModel> {
    return this.POST({
      Path: `/api/products/${productId}/invoice`
    })
  }
}
