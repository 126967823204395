import { IHttpClient } from "../..";
import { Config } from "../../../environment";
import { AutocompleteResultsModel } from "../../../models/api/google/AutocompleteResultModel";
import { PlaceDetailsResultsModel } from "../../../models/api/google/PlaceDetailsModel";
import APIBase from "../APIBase";

export default class GoogleAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  AddressAutocomplete(term: string, components: string | null = null, sessionId: string | null = null): Promise<AutocompleteResultsModel> {
    return this.GET({
      Path: '/api/geo/google/autocomplete',
      Anon: true,
      QueryParams: {
        term: term,
        sessionToken: sessionId ?? '',
        components: components ?? ''
      }
    })
  }

  GetPlaceDetails(placeId: string, sessionId: string | null = null): Promise<PlaceDetailsResultsModel> {
    return this.GET({
      Path: `/api/geo/google/places/${placeId}`,
      Anon: true,
      QueryParams: {
        sessionToken: sessionId ?? '',
      }
    })
  }
}