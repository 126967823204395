enum ValuationStatus
{
    None,
    Pending = 10,
    InProgress = 20,
    Deleted = 40,
    Completed = 50,
}

export { ValuationStatus }
