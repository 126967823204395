import GetValuerProfileModel from '../../../models/api/valuations/GetValuerProfileModel';
import UpdateValuerProfileModel from '../../../models/api/valuations/UpdateValuerProfileModel';
import APIBase from '../APIBase'

export default class PracticesAPI extends APIBase {
  GetValuersInPractice (practiceId: string): Promise<GetValuerProfileModel[]> {
    return this.GET({
      Path: `/api/practices/${practiceId}/valuers`
    })
  }

  GetMeInPractice (practiceId: string): Promise<GetValuerProfileModel> {
    return this.GET({
      Path: `/api/practices/${practiceId}/valuers/me`
    })
  }

  SetMeInPractice (practiceId: string, model: UpdateValuerProfileModel): Promise<GetValuerProfileModel> {
    return this.PUT({
      Path: `/api/practices/${practiceId}/valuers/me`,
      Body: model
    })
  }
}