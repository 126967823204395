import { CreateReportFromTemplateModel, GetReportDataModel, GetReportModel, GetReportTemplateModel, ReportType } from "../../../models/api/reports";
import { GetBlockBaseModel, UpdateBlockModel } from "../../../models/api/reports/builder/blocks";
import APIBase from "../APIBase";

export default class ReportsAPI extends APIBase {
  GetReport (reportId: string): Promise<GetReportModel> {
    return this.GET({
      Path: `/api/reports/${reportId}`
    })
  }

  GetBlocks (reportId: string): Promise<GetBlockBaseModel[]> {
    return this.GET({
      Path: `/api/reports/${reportId}/blocks`
    })
  }

  SaveBlock (reportId: string, blockId: string, model: UpdateBlockModel): Promise<GetBlockBaseModel> {
    return this.PUT({
      Path: `/api/reports/${reportId}/blocks/${blockId}`,
      Body: model
    })
  }

  async DeleteBlock (blockId: string): Promise<void> {
    await this.DELETE({
      Path: `/api/reports/blocks/${blockId}`
    })
  }
  
  CreateFromTemplate(data: CreateReportFromTemplateModel): Promise<GetReportModel> {
    return this.POST({
      Path: '/api/reports/from_template',
      Body: data
    })
  }

  GetTemplates (type: ReportType): Promise<GetReportTemplateModel[]> {
    return this.GET({
      Path: '/api/reports/templates',
      QueryParams: {
        type: type.toString()
      }
    })
  }

  GetReportData (id: string): Promise<GetReportDataModel> {
    return this.GET({
      Path: `/api/reports/${id}/data`
    })
  }

  async CreateBlock (reportId: string, index: number, type: string): Promise<void> {
    await this.POST({
      Path: `/api/reports/${reportId}/blocks`,
      Body: {
        Type: type,
        Index: index + 1
      }
    })
  }
}
