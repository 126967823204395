import { IHttpClient, Services } from "../..";
import { GetContactModel, CreateContactModel, CreateContactResultModel } from "../../../models/api/crm";
import APIBase from "../APIBase";

export default class CRMAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  async CreateContact(model: CreateContactModel, setAuthToken = false): Promise<CreateContactResultModel> {
    const result = await this.POST<CreateContactResultModel>({
      Path: '/api/contacts',
      Body: model,
      Anon: true
    })

    if (result.Token && setAuthToken) {
      Services.CredentialManager.SetToken(result.Token)
    }

    return result
  }

  async SaveContact(model: GetContactModel): Promise<GetContactModel> {
    
    const result = await this.PATCH<GetContactModel>({
      Path: `/api/contacts/${model.ContactId}`,
      Body: model
    })
    
    return result
  }

  async SendContactEmailForm(model: CreateContactModel): Promise<boolean>  {
    
    await this.POST<CreateContactModel>({
      Path: `/api/contacts/email`,
      Body: model,
      Anon: true
    })
    
    return true
  }
}
