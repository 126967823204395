import { IHttpClient } from "../..";
import { DateModel } from "../../../models/api/common";
import APIBase from "../APIBase";

export default class DateAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  async GetDate(htmlDate: string): Promise<DateModel> {
    return await this.POST({
      Path: '/api/dates/from_html_date',
      Body: {
        HTMLDate: htmlDate
      }
    })
  }  
}