import { GetContactModel } from "../../../../models/api/crm";
import GetGroupedContactModel from "../../../../models/api/crm/GetGroupedContactModel";
import IHttpClient from "../../../httpclient/IHttpClient";
import APIBase from "../../APIBase";

export default class AdminCRMAPI extends APIBase {
  constructor(client: IHttpClient) {
    super(client)
  }

  public GetContacts(): Promise<GetContactModel[]> {
    return this.GET({
      Path: '/api/contacts'
    })
  }

  async GetGroupedContacts(): Promise<GetGroupedContactModel[]> {
    return await this.GET({
      Path: '/api/contacts/grouped'
    })
  }

  public SaveContact(model: GetContactModel): Promise<GetContactModel> {
    return this.PATCH({
      Path: `/api/contacts/${model.ContactId}`,
      Body: model
    })
  }
}
