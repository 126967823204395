import { Logger, LogLevel } from "../../debug/Logger";
import { Service } from "../IService";
import ServiceType from "../ServiceTypes";

interface StoredObject {
  // eslint-disable-next-line @typescript-eslint/ban-types
  value: string | object | number | null | undefined;
}

class BrowserStorageProvider implements Service {
  Type: ServiceType = ServiceType.BrowserStorage;
  Id = 'BrowserStorage';

  private mechanism: Storage;

  constructor() {
    this.mechanism = window.localStorage;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public setItem (key: string, value: string | object | number | null | undefined): void {
    if (value === null) {
      this.mechanism.removeItem(key);
      return;
    }

    const storedVal: StoredObject = {
      value: value
    }

    if (storedVal) this.mechanism.setItem(key, JSON.stringify(storedVal));
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public getItem (key: string): string | object | number | null | undefined{
    const stored = this.mechanism.getItem(key);

    if(stored === null) return null;

    try {
      const storedEntity: StoredObject = JSON.parse(stored);

      return storedEntity.value;
    } catch (ex) {
      Logger.Log(LogLevel.Err, 'BrowserStorage', 'Failed to parse item with key', key);
    }

    return null;
  }

  public removeItem (key: string): void {
    this.mechanism.removeItem(key)
  }
}

export default BrowserStorageProvider;