import { IHttpClient } from "../..";
import BusinessBranchFilter from "../../../filters/BusinessBranchFilter";
import { BusinessBranchModel, BusinessModel, CreateBusinessModel, CreateBusinessResultModel } from "../../../models/api/businesses";
import { PagedResultSet } from "../../../models/api/common";
import APIBase from "../APIBase";

class BusinessAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  GetBusiness(id: string): Promise<BusinessModel> {
    return this.GET({
      Path: `/api/businesses/${id}`
    })
  }

  GetBusinessBranches(businessId: string): Promise<BusinessBranchModel[]> {
    return this.GET({
      Path: `/api/businesses/${businessId}/branches`
    })
  }

  CreateBusiness(model: CreateBusinessModel): Promise<CreateBusinessResultModel> {
    return this.POST({
      Path: '/api/businesses',
      Body: model
    })
  }

  GetBranch(branchId: string): Promise<BusinessBranchModel> {
    return this.GET({
      Path: `/api/businesses/branches/${branchId}`
    })
  }

  SearchBranches(filter: BusinessBranchFilter): Promise<PagedResultSet<BusinessBranchModel>> {
    return this.GET({
      Path: '/api/businesses/branches',
      QueryParams: filter.ToQueryParams()
    })
  }
}

export default BusinessAPI