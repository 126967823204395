






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LinkButton extends Vue {
  @Prop({ required: true })
  private readonly to!: string;

  @Prop({ default: false })
  private readonly external!: boolean;

  handleClick () {
    if (this.external) {
      window.open(this.to, '_blank')
    } else {
      this.$router.push(this.to)
    }
  }
}
