



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Navigation from './Navigation.vue'
import ContactUs from '../ContactUs.vue'

@Component({
  components: {
    Navigation,
    ContactUs
  }
})
export default class Header extends Vue {
  private isStuck = false

  private mobileNavActive = false

  @Prop()
  private readonly isContactUsShown!: boolean;

  mounted () {
    window.addEventListener('scroll', this.checkScroll, { passive: true })

    this.checkScroll()

    this.$router.afterEach(() => {
      this.mobileNavActive = false
    })
  }

  checkScroll () {
    this.isStuck = window.scrollY > 0
  }

  @Watch('toggleMobileNavActive')
  toggleMobileNavActive () {
    this.mobileNavActive = !this.mobileNavActive
  }
}
