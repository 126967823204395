import { Logger, LogLevel } from "../debug/Logger";
import { Config } from "../environment";
import IConfig from "../environment/IConfig";
import { API, ServiceManager } from "../services";
import BrowserStorageProvider from "../services/browserstorage/BrowserStorageProvider";
import CredentialManager from "../services/credentialmanager/CredentialManager";
import EventBus from "../services/eventbus/EventBus";
import HttpProviderType from "../services/httpclient/HttpProviderType";
import FetchClient from "../services/httpclient/providers/FetchClient";
import StripeService from "../services/payment/stripe/StripeService";

class Bootstrapper {
  static Initialise(config: IConfig): Promise<boolean> {
    Logger.Log(LogLevel.Debug, 'Bootstrapper', 'Beginning bootstrap process', config);

    return new Promise((resolve) => {
      Logger.SetLevel(config.Logging.LogLevel);

      Config.Initialise(config);
  
      this.InitServices(config);
  
      Logger.Log(LogLevel.Debug, 'Bootstrapper', 'Finished bootstrapping process');

      resolve(true);
    });
  }

  private static InitServices(config: IConfig) {
    //Event Bus
    ServiceManager.Register(new EventBus())

    //Browser Storage
    ServiceManager.Register(new BrowserStorageProvider());

    //Credential Manager
    ServiceManager.Register(new CredentialManager());

    //HTTP Client
    switch(Config.Http.Provider) {
      case HttpProviderType.Fetch:
        ServiceManager.Register(new FetchClient());
      break;
    }

    ServiceManager.Register(new API());

    if (config.Stripe) {
      ServiceManager.Register(new StripeService(config.Stripe))
    }
  }
}

export default Bootstrapper;