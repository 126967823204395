














































































































































































import { Component, Vue } from 'vue-property-decorator'
import BlogCard from '../components/Blogs/BlogCard.vue'
import GridNav from '../components/Grid/GridNav.vue'
import GridNavItem from '../components/Grid/GridNavItem.vue'
import SearchBar from '../components/Valuation/SearchBar.vue'
import CallToAction from '../components/CallToAction.vue'
import About from './About.vue'

@Component({
  components: {
    SearchBar,
    GridNav,
    GridNavItem,
    BlogCard,
    About,
    CallToAction
  }
})
export default class FAQ extends Vue {

}
