






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class GridNav extends Vue {
  @Prop({ default: 4 })
  private readonly across!: number;
}
