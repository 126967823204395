






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
  @Prop({ required: true })
  private readonly icon!: string;

  @Prop({ default: '#fff' })
  private readonly color!: string;
}
