import { BusinessBranchModel } from "../../../models/api/businesses";
import GetPracticeModel from "../../../models/api/practices/GetPracticeModel";
import { LoggedInUserModel, LoginConfigResult, LoginModel, LoginResult, RegisterUserModel, RegisterUserResult } from "../../../models/api/users";
import IHttpClient from "../../httpclient/IHttpClient";
import APIBase from "../APIBase";

class UsersAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  Me(): Promise<LoggedInUserModel> {
    return this.GET<LoggedInUserModel>({
      Path: '/api/users/me',
    })
  }

  GetPractices(): Promise<GetPracticeModel[]> {
    return this.GET({
      Path: '/api/users/me/practices'
    })
  }

  Register(model: RegisterUserModel): Promise<RegisterUserResult> {
    return this.POST({
      Path: '/api/users/register',
      Body: model,
      Anon: true
    })
  }

  GetBusinessBranches(): Promise<BusinessBranchModel[]> {
    return this.GET({
      Path: '/api/users/me/business_branches'
    })
  }
}

export default UsersAPI;