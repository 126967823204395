import { BrowserStorageProvider } from "..";
import { AuthToken } from "../../models/api/auth";
import { Service } from "../IService";
import ServiceManager from "../ServiceManager";
import ServiceType from "../ServiceTypes";

class CredentialManager implements Service {
  Type: ServiceType = ServiceType.CredentialManager;
  Id = 'CredentialManager';

  private CachedToken: AuthToken | null = null;

  StorageProvider: BrowserStorageProvider;

  constructor() {
    this.StorageProvider = ServiceManager.Require(ServiceType.BrowserStorage)
  }

  public DeleteOAuthCredential(): void {
    this.CachedToken = null

    this.StorageProvider.removeItem('tm_auth');
  }

  public SetToken (token: AuthToken | null): void {
    this.CachedToken = token

    this.StorageProvider.setItem('tm_auth', token)
  }

  public GetToken (): AuthToken | null {
    if (this.CachedToken) return this.CachedToken

    const token = this.StorageProvider.getItem('tm_auth')

    if (token) {
      return token as AuthToken
    }

    return null
  }
}

export default CredentialManager;