import { render, staticRenderFns } from "./Cards.vue?vue&type=template&id=5489567d&scoped=true&"
var script = {}
import style0 from "./Cards.vue?vue&type=style&index=0&id=5489567d&scoped=true&lang=css&"
import style1 from "./Cards.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5489567d",
  null
  
)

export default component.exports