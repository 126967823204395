import { IHttpClient } from "../..";
import { CreateEnumerationValueModel, EnumerationGroupModel, UpdateCustomEnumValueModel } from "../../../models/api";
import EnumerationValueModel from "../../../models/api/enumerations/EnumerationValueModel";
import EnumValueType from "../../../models/api/enumerations/EnumValueType";
import APIBase from "../APIBase";

class EnumerationsAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  GetEnumValuesForType(type: EnumValueType, embed = false): Promise<EnumerationValueModel[]> {
    return this.GET({
      Path: `/api/enumerations/${type}/values`,
      QueryParams: {
        embed: embed ? 'true' : 'false'
      },
      Anon: true
    })
  }

  GetEnumValueChildren(id: string, types: EnumValueType[] | null = null): Promise<EnumerationValueModel[]> {
    return this.GET({
      Path: `/api/enumerations/${id}/children`,
      QueryParams: {
        types: types === null ? '' : types.join(',')
      },
      Anon: true
    })
  }

  GetGroups(): Promise<EnumerationGroupModel[]> {
    return this.GET({
      Path: `/api/enumerations/groups`,
      Anon: true
    })
  }

  GetGroup(type: EnumValueType): Promise<EnumerationGroupModel> {
    return this.GET({
      Path: `/api/enumerations/groups/${type}`,
      Anon: true
    })
  }

  CreateValue(model: CreateEnumerationValueModel): Promise<EnumerationValueModel> {
    return this.POST({
      Path: `/api/enumerations`,
      Anon: true,
      Body: model
    })
  }

  UpdateValue(id: string, model: UpdateCustomEnumValueModel): Promise<EnumerationValueModel> {
    return this.PATCH({
      Path: `/api/enumerations/${id}`,
      Body: model
    })
  }

  GetValue(id: string): Promise<EnumerationValueModel> {
    return this.GET({
      Path: `/api/enumerations/${id}`,
      Anon: true
    })
  }
}

export default EnumerationsAPI
