import { IHttpClient } from "../../..";
import { FranchiseModel } from "../../../../models/api/admin";
import APIBase from "../../APIBase";

class FranchiseAPI extends APIBase {
  constructor(httpClient: IHttpClient) {
    super(httpClient);
  }

  GetFranchises(): Promise<FranchiseModel[]> {
    return this.GET({ Path: '/api/admin/franchises' })
  }

  Create(businessBranchId: string): Promise<FranchiseModel> {
    return this.POST({ Path: '/api/admin/franchises', Body: { BusinessBranchId: businessBranchId } })
  }

  Get(franchiseId: string): Promise<FranchiseModel> {
    return this.GET({ Path: `/api/admin/franchises/${franchiseId}` })
  }
}

export default FranchiseAPI